import React from 'react'
import { graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { GatsbyImage } from 'gatsby-plugin-image'
import Layout from '../components/layout'

class MoviesPage extends React.Component {
  render() {
    const moviesPage = this.props.data.contentfulMoviesPage
    let sectionCount = 0
    return (
      <Layout location={this.props.location}>
        <section className="top">
          <Helmet title={moviesPage.title} />
          <h1 className="hero-title">{moviesPage.title}</h1>
        </section>
        <section>
          <header>
            <p className="section-number">0{(sectionCount += 1)}</p>
            <h2 id="motion-pictures">Motion Pictures</h2>
          </header>
          <div
            className="summary mt section-description"
            dangerouslySetInnerHTML={{
              __html: moviesPage.summary.childMarkdownRemark.html,
            }}
          />
          {moviesPage.movies.length > 0 ? (
            <ul className="movie-list section-description">
              {moviesPage.movies.map((movie) => {
                return (
                  <li key={movie.id} id={movie.slug}>
                    <div className="movie-poster">
                      <GatsbyImage
                        image={movie.movieImage.gatsbyImageData}
                        alt=""
                      />
                    </div>
                    <div className="movie-deets">
                      {movie.title && <h3>{movie.title}</h3>}
                      {movie.summary && (
                        <div
                          dangerouslySetInnerHTML={{
                            __html: movie.summary.childMarkdownRemark.html,
                          }}
                        />
                      )}
                    </div>
                  </li>
                )
              })}
            </ul>
          ) : (
            <p>No featured movies right now.</p>
          )}
        </section>
      </Layout>
    )
  }
}

export default MoviesPage

export const pageQuery = graphql`
  query MoviesPageQuery {
    contentfulMoviesPage(id: { eq: "32ae2401-8962-5e93-a1ae-fb1feb75e5e8" }) {
      movies {
        id
        slug
        summary {
          childMarkdownRemark {
            html
          }
        }
        movieImage {
          gatsbyImageData(width: 480, layout: CONSTRAINED)
        }
        title
      }
      summary {
        childMarkdownRemark {
          html
        }
      }
      title
    }
  }
`
